import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function siteMetadata() {
    const data = useStaticQuery(graphql`
        query siteMetaData {
            allPrismicGlobal {
                edges {
                    node {
                        lang
                        data {
                            site_title
                            site_meta_description
                            social_share_image {
                                localFile {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    return data;
}

function SEO({
    bodyClass, description, lang, meta, pathname, socialImage, title,
}) {
    const data = siteMetadata();
    let metaTitle;
    let metaDescription;
    let socialShareImage;
    const siteAuthor = 'David Sewell';
    const siteURL = 'https://dsewell.com';

    data.allPrismicGlobal.edges.map(({ node }) => (
        node.lang === lang && (
            metaTitle = node.data.site_title
        )
    ));

    data.allPrismicGlobal.edges.map(({ node }) => (
        node.lang === lang && (
            metaDescription = node.data.site_meta_description
        )
    ));

    if (description !== '') {
        metaDescription = description;
    }

    data.allPrismicGlobal.edges.map(({ node }) => (
        node.lang === lang && (
            socialShareImage = `${siteURL}${node.data.social_share_image.url}`
        )
    ));

    if (socialImage !== '') {
        socialShareImage = `${siteURL}${socialImage}`;
    }

    return (
        <Helmet
            htmlAttributes={{ lang }}
            bodyAttributes={{ class: bodyClass }}
            title={title}
            titleTemplate={title !== ' ' ? `%s | ${metaTitle}` : metaTitle}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    name: 'article:author',
                    content: siteAuthor,
                },
                {
                    property: 'og:title',
                    content: title !== ' ' ? `${title} | ${metaTitle}` : metaTitle,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:image',
                    content: socialShareImage,
                },
                {
                    property: 'og:url',
                    content: `${siteURL}${pathname}`,
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:creator',
                    content: siteAuthor,
                },
                {
                    name: 'twitter:title',
                    content: title !== ' ' ? `${title} | ${metaTitle}` : metaTitle,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'twitter:image',
                    content: socialShareImage,
                },
                {
                    name: 'twitter:url',
                    content: `${siteURL}${pathname}`,
                },
            ]
                .concat(meta)
            }
        />
    );
}

SEO.defaultProps = {
    bodyClass: '',
    description: '',
    meta: [],
    socialImage: '',
};

SEO.propTypes = {
    bodyClass: PropTypes.string,
    description: PropTypes.string,
    lang: PropTypes.string.isRequired,
    meta: PropTypes.array,
    socialImage: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default SEO;
